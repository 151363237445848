import Misc from './misc';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class headerMenu { 
  constructor() {

    this.mobileMenuLayout = Misc.getMediaQueries()['mobilemenu-layout'];
    this.triggerMenu = document.querySelector('.site-header .item-menu-trigger a');
    this.headerWrapper = document.querySelector('.site-header')
    this.menuWrapper = document.querySelector('.site-header .item-menu');

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleOnResize = this.handleOnResize.bind(this);
    this.handleOnScroll = this.handleOnScroll.bind(this);


    // Init if mobilemenu layout
    if (Misc.getViewport().width < this.mobileMenuLayout) {
      window.bodyLayout = 'mobilemenu-layout';
      this.triggerMenu.addEventListener('click', this.toggleMenu);
    }

    // [].slice.call(document.querySelectorAll('.menu-item-has-children')).forEach(item => {
    //   item.firstElementChild.addEventListener('click', this.handleSubMenu)
    // })

    // Handle on Resize
    window.addEventListener('resize', this.handleOnResize);
    // window.addEventListener('scroll', this.handleOnScroll);

  }

  toggleMenu(event) {
    event.preventDefault();

    if (!this.triggerMenu.classList.contains('active')) {
      this.openMenu();
    } else {
      this.closeMenu();
    }

  }

  getMenuWrapperHeight() {
    return parseInt(this.headerWrapper.querySelector('.site-header--logo').getBoundingClientRect().height);
  }

  openMenu() {
    // let menuNavHeight = this.getMenuNavHeight();
    // const menuWrapperHeight = this.getMenuWrapperHeight();
    // const viewportHeight = Misc.getViewport().height;
    
    // this.headerWrapper.style.height = `${viewportHeight}px`;
    this.triggerMenu.classList.add('active');
    this.headerWrapper.classList.add('menu-on');
    disableBodyScroll(this.headerWrapper)
  
  }

  closeMenu() {
    this.triggerMenu.classList.remove('active');
    this.headerWrapper.classList.remove('menu-on');
    enableBodyScroll(this.headerWrapper);
  }

  handleOnResize() {
    if (Misc.getViewport().width > this.mobileMenuLayout && window.bodyLayout === 'nondesktop-layout') {
      window.bodyLayout = null;
      this.closeMenu();
      clearAllBodyScrollLocks();
    }

    if (Misc.getViewport().width < this.mobileMenuLayout && window.bodyLayout !== 'nondesktop-layout') {
      window.bodyLayout = 'nondesktop-layout';
      this.triggerMenu.addEventListener('click', this.toggleMenu);
    }
  }

  handleOnScroll(event) {
    const scrollTop = document.documentElement.scrollTop;

    if (!this.headerWrapper.classList.contains('header-wrapper--absolute')) {
      return false;
    }
    
    if (Misc.getViewport().width < this.mobileMenuLayout) {
      return this.headerWrapper.classList.remove('header-wrapper--fixed')
    }

    if (scrollTop > 0) {
      this.headerWrapper.classList.add('header-wrapper--fixed')
    } else {
      this.headerWrapper.classList.remove('header-wrapper--fixed')
    }
  }

}

new headerMenu();