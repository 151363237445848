import 'web-animations-js';
import Misc from './misc';

/**
<article class="item list-accordion--item">
  <header class="item-header">
    <a href="#">
      Title
    </a>
  </header>
<div class="item-content">
    <div class="item-content--inner">Content</div>
  </div>
</article>
**/
class ListAccordionToggle {
  constructor(options) {
    this.currentTarget = options.currentTarget;
    this.contentWrapper = options.contentWrapper;
    this.thisContent = options.thisContent;
    this.contentHeight = options.contentHeight;

    if (!this.currentTarget.classList.contains('active')) {
      this.openTab();
    } else {
      this.closeTab();
    }
  }

  openTab() {
    this.currentTarget.classList.add('active');
    
    this.contentWrapper.style.removeProperty('height');
    // this.contentWrapper.style.setProperty('height', 0);
    const animation = this.contentWrapper.animate({
      opacity: [0, 1],
      height: [0, `${this.contentHeight}px`],
      transform: ['translateY(-1rem)', 'translateY(0)']
    }, {
      duration: 300, 
      fill: 'forwards',
      easing: 'ease-in-out'
    });
    animation.onfinish = () => {
      this.contentWrapper.style.setProperty('transform', 'none');
      animation.cancel();
    }
  }

  closeTab() {
    const animation = this.contentWrapper.animate({
      opacity: [1, 0],
      height: [`${this.contentHeight}px`, 0],
      transform: ['translateY(0)', 'translateY(-1rem)']
    }, {
      duration: 300, 
      fill: 'forwards',
      easing: 'ease-in-out'
    });
    this.currentTarget.classList.remove('active');
    animation.onfinish = () => {
      this.contentWrapper.style.setProperty('height', 0);
      this.contentWrapper.style.removeProperty('transform');
      animation.cancel();
    }
  }
}

class listAccordion {
  constructor(options) {
    this.options = options;
    this.contentList = document.querySelectorAll(this.options.elementListSelector)
    this.item
    this.handleAccordion = this.handleAccordion.bind(this);

    [].slice.call(this.contentList).forEach(item => {
      item.addEventListener('click', this.handleAccordion);
    });
  }

  handleAccordion(event) {
    event.preventDefault();
    
    const options = {
      event: event,
      currentTarget: event.currentTarget,
      contentWrapper: event.currentTarget.parentElement.querySelector('.item-content'),
      thisContent: event.currentTarget.parentElement.querySelector('.item-content .item-content--inner'),
      contentHeight: parseInt(event.currentTarget.parentElement.querySelector('.item-content .item-content--inner').getBoundingClientRect().height),
    };

    new ListAccordionToggle(options);
  }
}

new listAccordion({
  elementListSelector: '.list-accordion--item-header'
});