import AOS from 'aos';
import 'aos/dist/aos.css';
import './list-accordion'

/**
 * @see https://github.com/michalsnik/aos
 */
window.addEventListener('load', AOS.refresh);
AOS.refresh(); 
AOS.init({
  disable: 'mobile',
  once: true,
  // startEvent: 'DOMContentLoaded',
  offset: 30,
  duration: 600,
  once: true,
});